@use './../../styles/abstracts' as *;

$message-animation-time: 500ms;
$message-animation-timing-function: linear;

.message-box {
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  column-gap: 10px;
  width: 300px;
  height: auto;
  padding: 18px 38px 18px 32px;
  background-color: $success-color;
  border-radius: 40px;
  animation: $message-animation-time $message-animation-timing-function slide_from_top;

  @include mq(extraSmall) {
    width: 377px;
  }

  &.hide {
    animation: $message-animation-time $message-animation-timing-function forwards slide_to_top;
  }

  &__text {
    color: $white;
    font-size: 16px;
    font-family: $font-main-bold;
    line-height: 20px;
  }

  &__icon {
    @include clickable-element;
  }
}

@keyframes slide_from_top {
  from {
    transform: translateY(-100%);
    opacity: 0;
    visibility: hidden;
  }
  to {
    transform: translateY(0);
    opacity: 1;
    visibility: visible;
  }
}

@keyframes slide_to_top {
  from {
    transform: translateY(0);
    opacity: 1;
    visibility: visible;
  }
  to {
    transform: translateY(-100%);
    opacity: 0;
    visibility: hidden;
  }
}
