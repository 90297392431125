@use './../../../styles/abstracts' as *;

.gradient-preview {
  @include theme-transition;
  min-height: 496px;
  display: grid;
  grid-template-rows: auto 1fr;
  grid-template-columns: 1fr;
  grid-row-gap: 22px;
  padding: 34px 32px 32px;
  background-color: var(--surface-color);
  border: 2px solid var(--border-color);
  border-radius: 30px;

  @include mq(small) {
    width: $section-width;
  }

  &__panel {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(38px, 38px));
    grid-gap: 22px;
  }

  &__gradient-container {
    @include theme-transition;
    @include chess-board;
    min-height: 364px;
    border: 2px solid var(--border-color);
    border-radius: 20px;
    overflow: hidden;
  }

  &__gradient {
    width: 100%;
    height: 100%;
  }
}

.gradient-preview-pallet {
  @include theme-transition;
  @include clickable-element;
  @include flex-center;
  position: relative;
  width: 48px;
  height: 48px;
  background-color: var(--surface-color);
  border: 2px solid transparent;
  border-radius: 50%;

  &.active {
    border: 2px solid var(--selected-color);
  }

  &:hover {
    transition: $hover-transition-time;

    .gradient-preview-pallet__delete-btn {
      opacity: 1;
    }
  }

  &__inner {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 1px solid var(--border-color);
    transition: border-color $theme-transition-time $theme-transition-timing-function;
  }

  &__delete-btn {
    @include theme-transition;
    @include flex-center;
    position: absolute;
    visibility: hidden;
    top: -4px;
    right: -4px;
    width: 18px;
    height: 18px;
    background-color: var(--surface-color);
    border: 1px solid transparent;
    border-radius: 50%;
    opacity: 0;

    &.canDelete {
      visibility: visible;
    }

    &:hover {
      transition: $hover-transition-time;
      border-color: var(--border-color);
    }
  }

  &__delete-icon {
    fill: var(--primary-color);
  }
}
