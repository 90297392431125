@use '../../styles/abstracts/index' as *;

.header {
  @include theme-transition;
  height: 84px;
  display: grid;
  grid-template-columns: auto auto;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px 0 21px;
  background-color: var(--surface-color);
  border: 2px solid var(--border-color);
  border-radius: 40px;
}

.logo {
  display: grid;
  grid-template-columns: auto auto;
  align-items: center;
  grid-column-gap: 8px;

  @include mq(extraSmall) {
    grid-column-gap: 12px;
  }

  &__icon {
    scale: 0.8;

    @include mq(extraSmall) {
      scale: 1;
    }
  }

  &__title {
    @include theme-transition;
    font-size: 20px;
    font-family: $font-main-bold;
    color: var(--title-color);
    line-height: 30px;

    @include mq(extraSmall) {
      font-size: 22px;
    }

    @include mq(small) {
      font-size: 24px;
    }
  }
}
