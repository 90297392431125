@use 'abstracts' as *;

*,
*::after,
*::before, {
  box-sizing: border-box;
}

html,
body {
  @include theme-transition;
  padding: 0;
  margin: 0;
  background-color: var(--background-color);
  font-family: $font-main-regular;
}

h1, h2, p {
  margin: 0;
}

main {
  padding: 10px 20px 20px;

  @include mq(extraSmall) {
    padding: 15px 40px 30px;
  }

  @include mq(small) {
    padding: 20px 80px 42px;
  }
}
