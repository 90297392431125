@use './../../styles/abstracts' as *;

.gradient-generator {

  &__main {
    margin: 40px 0;
    display: flex;
    flex-direction: column;
    row-gap: 24px;

    @include mq(small) {
      display: grid;
      justify-content: center;
      gap: 24px;
    }

    @include mq(large) {
      grid-template-columns: auto auto;
    }
  }

  &__subheader {
    @include theme-transition;
    font-size: 20px;
    font-family: $font-main-bold;
    line-height: 25px;
    color: var(--text-color);
  }
}

.gradient-generator-settings {
  display: flex;
  flex-direction: column;
  row-gap: 24px;

  &__top {
    min-height: 311px;
  }
}


